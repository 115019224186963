import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import useToast from '../../../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../../../state/store/reducers/ui/headerSlice';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, ErrorLoading, ListNoData } from '../../../../../../../components/common';
import moduleService from '../../../../../../../services/api/subscriptions/modulesService';
import PopoverWithFloat from '../../../../../../../components/hoc/PopoverWithFloat';
import { Menu } from '@headlessui/react';
import FeatureListFilters from '../../features/features-list/FeatureListFilters';
import { format } from 'date-fns';
import useAlert from '../../../../../../../state/hooks/useAlert';
import featureService from '../../../../../../../services/api/subscriptions/featureService';
import { ListLoader } from '../../../../../../../components/loaders';
import ModalModuleForm from '../../modals/ModalModuleForm';
import ModalModuleDetails from '../../modals/ModalModuleDetails';
import ModalFeatureForm from '../../modals/ModalFeatureForm';
import ModalFeatureDetails from '../../modals/ModalFeatureDetails';


function ManageModulePage() {
    const dispatch = useDispatch();
    const location = useLocation();

    const { moduleId } = useParams();
    const { addToast } = useToast();
    const { openAlertConfirm } = useAlert();

    const [moduleForm, setModuleForm] = useState({ open: false });
    const [moduleDetails, setModuleDetails] = useState({ open: false });
    const [featureForm, setFeatureForm] = useState({ open: false, feature: null, });
    const [featureDetails, setFeatureDetails] = useState({ open: false, feature: null });

    const [searchFilters, setSearchFilters] = useState<any>({});


    const queryClient = useQueryClient();
    const queryKey = ['subscriptions', 'modules', Number(moduleId)];
    const moduleQuery = useQuery({
        queryKey: queryKey,
        queryFn: () => moduleService.getModule(moduleId as string),
        placeholderData: { ...location?.state?.module },
        enabled: typeof moduleId === 'string',
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });


    useEffect(() => {
        dispatch(changePageInfo({ title: "Manage Module" }));

        /*   if (moduleId) {
              if (location.state && location.state.module) {
                  setModule(location.state.module);
              } else {
                  // fetch module;
              }
          } */
    }, []);



    const featureDeleteMutation = useMutation({ mutationFn: (id: number) => featureService.deleteFeature(moduleQuery.data.id, { featureIds: id }) });
    const handleFeatureDelete = (feature: any) => {
        openAlertConfirm({
            title: 'Delete Feature',
            message: `Are you sure you want to delete ${feature.name} feature?`,
            onConfirmed: () => {
                featureDeleteMutation.mutate(feature.id, {
                    onSuccess: () => {
                        queryClient.setQueryData<any>(queryKey, (oldData: any) => {
                            const index = oldData.features.findIndex((item: any) => item.id === feature.id);
                            const newData = JSON.parse(JSON.stringify(oldData));
                            newData.features.splice(index, 1);
                            return newData;
                        });

                        addToast({
                            text: "Feature deleted successfully",
                            type: "success",
                        });
                    },
                    onError: () => {
                        addToast({
                            text: "Feature could not be deleted",
                            type: "error",
                        });
                    }
                });
            }
        })
    }



    const filteredFeatures = moduleQuery.data?.features?.filter((item: any) => {
        if (searchFilters?.name && !item.name?.toLowerCase()?.includes(searchFilters?.name?.toLowerCase())) {
            return false;
        }

        if (searchFilters?.status && item.status !== searchFilters?.status) {
            return false;
        }

        return true;
    }) || [];


    return (
        <React.Fragment>
            {
                !moduleQuery.isFetching && !moduleQuery.isError &&
                <div>
                    <div className='flex flex-col sm:flex-row flex-wrap justify-between items-end  border-b dark:border-gray-700 gap-y-4 gap-x-24 md:gap-x-40 xl:gap-x-56 2xl:gap-x-80 pb-5 mb-5'>
                        <div className='flex-1'>
                            {/* <p className='font-medium text-sm text-gray-500 dark:text-gray-400 mb-1'>Module</p> */}
                            <p className='font-semibold text-xl mb-1'>{moduleQuery.data?.name}</p>
                            <p className='text-gray-500 dark:text-gray-400 line-clamp-2'>{moduleQuery.data?.description}</p>
                        </div>


                        <div className='flex justify-end items-center gap-x-6'>
                            <span className={`inline-block font-medium uppercase ${moduleQuery.data?.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-2 px-4`}>
                                {moduleQuery.data?.status || "Inactive"}
                            </span>

                            <Menu as="div" className="relative inline-block text-left">
                                <PopoverWithFloat placement='bottom-end' portal>
                                    <Menu.Button type='button' className={`cursor-pointer flex items-center gap-x-2 transition-colors duration-300 bg-gray-100 hocus:bg-gray-200  dark:bg-gray-800 dark:hocus:bg-gray-600 rounded-lg py-2.5 px-4`}>
                                        <span>Action</span>
                                        <i className="ri-arrow-down-s-line opacity-70"></i>
                                    </Menu.Button>

                                    <Menu.Items as='div' unmount={false} className="w-52 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                        <Menu.Item
                                            as="button"
                                            type='button'
                                            className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                            onClick={() => setModuleDetails({ open: true })}
                                        >
                                            View Full Details
                                        </Menu.Item>

                                        <Menu.Item
                                            as="button"
                                            type='button'
                                            className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3"
                                            onClick={() => setModuleForm({ open: true })}
                                        >
                                            Edit Module
                                        </Menu.Item>
                                    </Menu.Items>
                                </PopoverWithFloat>
                            </Menu>
                        </div>
                    </div>


                    <div className='flex flex-wrap justify-between items-start gap-y-1 gap-x-6 mb-4'>
                        <p className='font-semibold text-lg'>Features</p>
                    </div>


                    {
                        moduleQuery.data?.features && moduleQuery.data?.features.length > 0 &&
                        <React.Fragment>
                            <div className='flex flex-row-reverse flex-wrap justify-between items-start'>
                                <Button type='button' onClick={() => setFeatureForm({ open: true, feature: null })} className='py-2.5'>Create Feature</Button>
                                <FeatureListFilters
                                    initialData={searchFilters}
                                    /* onSearch={(values) => {
                                        const filters: any = {};
                                        if (values.name) { filters['name'] = values.name; }
                                        if (values.status) { filters['status'] = values.status; }
                                        setSearchFilters(filters);
                                    }} */
                                    onSearch={(values) => setSearchFilters(values)}
                                    searching={false}
                                    showSubmitButton={false}
                                />
                            </div>


                            {
                                filteredFeatures.length > 0 &&
                                <div className='max-w-full overflow-x-auto mb-6'>
                                    <table className="border-collapse table-auto w-full">
                                        <thead>
                                            <tr className='text-left border-b-2 dark:border-gray-700'>
                                                <th className='py-6 px-6'>No.</th>
                                                <th className='py-6 px-6'>Name</th>
                                                <th className='py-6 px-6'>Description</th>
                                                <th className='py-6 px-6'>Status</th>
                                                <th className='py-6 px-6'>Date Created</th>
                                                <th className='py-6 px-6 text-right'>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y dark:divide-gray-700/60">
                                            {
                                                filteredFeatures.map((featureItem: any, featureItemIndex: number) =>
                                                    <tr key={featureItem.id}>
                                                        <td className='py-4 px-6'>{featureItemIndex + 1}</td>
                                                        <td className='py-4 px-6'>
                                                            <button type='button' onClick={() => setFeatureDetails({ open: true, feature: featureItem })} className='transition-colors duration-300 hocus:text-blue'>
                                                                {featureItem.name}
                                                            </button>
                                                        </td>
                                                        <td className='py-4 px-6' title={featureItem.description}>
                                                            <p className='line-clamp-1'>{featureItem.description}</p>
                                                        </td>
                                                        <td className='py-4 px-6'>
                                                            <span className={`inline-block font-medium text-sm uppercase ${featureItem.status === "ACTIVE" ? "bg-green-500/10 text-green-500" : "bg-red-500/10 text-red-500"} rounded-lg py-1 px-3`}>
                                                                {featureItem.status || "Inactive"}
                                                            </span>
                                                        </td>
                                                        <td className='py-4 px-6 whitespace-nowrap'>{format(new Date(featureItem.createdDate), 'd LLL yyyy h:mm a')}</td>
                                                        <td className='py-4 px-6 text-right'>
                                                            <Menu as="div" className="relative inline-block text-left">
                                                                <PopoverWithFloat placement='bottom-end' portal>
                                                                    <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                                        <i className="ri-more-2-fill"></i>
                                                                    </Menu.Button>

                                                                    <Menu.Items as='div' unmount={false} className="w-52 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                                        <Menu.Item as="button" type='button' onClick={() => setFeatureDetails({ open: true, feature: featureItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                            View
                                                                        </Menu.Item>
                                                                        <Menu.Item as="button" type='button' onClick={() => setFeatureForm({ open: true, feature: featureItem })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                            Edit
                                                                        </Menu.Item>
                                                                        <Menu.Item as="button" type='button' onClick={() => handleFeatureDelete(featureItem)} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg text-red-500 px-5 py-3">
                                                                            Delete
                                                                        </Menu.Item>
                                                                    </Menu.Items>
                                                                </PopoverWithFloat>
                                                            </Menu>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </React.Fragment>
                    }


                    {
                        filteredFeatures.length === 0 && moduleQuery.data?.features && moduleQuery.data?.features.length === 0 &&
                        //moduleQuery.data?.features && moduleQuery.data?.features.length === 0 && Object.keys(searchFilters).length === 0 &&
                        <ListNoData title='No Feature Found' showIcon={false} description='There are no feature currently setup in SmartSapp' className='px-8 py-16'>
                            <Button type='button' onClick={() => setFeatureForm({ open: true, feature: null })}>Add a Feature Here</Button>
                        </ListNoData>
                    }

                    {
                        //moduleQuery.data?.features && moduleQuery.data?.features.length === 0 && Object.keys(searchFilters).length > 0 &&
                        filteredFeatures.length === 0 && moduleQuery.data?.features && moduleQuery.data?.features.length > 0 &&
                        <ListNoData title='No Feature Found' showIcon={false} description='No feature was found for the selected scenarios' className='px-8 py-16' />
                    }
                </div>
            }


            {
                // if it's loading
                moduleQuery.isFetching &&
                <ListLoader loadingText='Loading Module' className='px-8 py-24' />
            }

            {
                // if not found
                moduleQuery.isFetched && moduleQuery.isError && moduleQuery.error.message === 'NOT_FOUND' &&
                <ErrorLoading title='Module Not Found' message='Module may have been removed or never existed' className='px-8 py-24' />
            }

            {
                // if there's an error loading
                moduleQuery.isFetched && moduleQuery.isError && moduleQuery.error.message !== 'NOT_FOUND' &&
                <ErrorLoading title='Error Loading Module' message='An unexpected error occurred while loading module' className='px-8 py-24' onTryAgain={moduleQuery.refetch} />
            }




            <ModalModuleForm
                open={moduleForm.open}
                module={moduleQuery.data}
                onUpdated={(data) => {
                    queryClient.setQueryData<any>(queryKey, (oldData: any) => ({ ...oldData, ...data }));

                    addToast({
                        text: `Module updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setModuleForm((prevState) => ({ ...prevState, open: false }))}
            />


            <ModalFeatureForm
                open={featureForm.open}
                feature={featureForm.feature}
                module={moduleQuery.data}
                onAdded={(data) => {
                    queryClient.setQueryData<any>(queryKey, (oldData: any) => {
                        const newData = JSON.parse(JSON.stringify(oldData));
                        newData?.features?.unshift(data);
                        return newData;
                    });


                    addToast({
                        text: `Feature created successfully`,
                        type: "success"
                    });
                }}
                onUpdated={(data) => {
                    queryClient.setQueryData<any>(queryKey, (oldData: any) => {
                        const index = oldData.features.findIndex((item: any) => item.id === data.id);
                        const newData = JSON.parse(JSON.stringify(oldData));
                        newData.features[index] = data;
                        return newData;
                    });

                    addToast({
                        text: `Feature updated successfully`,
                        type: "success"
                    });
                }}
                onClose={() => setFeatureForm((prevState) => ({ ...prevState, open: false }))}
            />


            <ModalModuleDetails
                open={moduleDetails.open}
                module={moduleQuery.data}
                onClose={() => setModuleDetails((prevState) => ({ ...prevState, open: false }))}
            />

            <ModalFeatureDetails
                open={featureDetails.open}
                feature={featureDetails.feature}
                onClose={() => setFeatureDetails((prevState) => ({ ...prevState, open: false }))}
            />
        </React.Fragment>
    );
}

export default ManageModulePage;