import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import useToast from '../../../../../../../state/hooks/useToast';
import { changePageInfo } from '../../../../../../../state/store/reducers/ui/headerSlice';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as yup from 'yup';
import regexStrings from '../../../../../../../config/regexStrings';
import { Form, Formik } from 'formik';
import { Button, ErrorLoading, } from '../../../../../../../components/common';
import SubscriptionPlanDetailsForm from './SubscriptionPlanDetailsForm';
import classNames from 'classnames';
import SubscriptionPlanModuleFeaturesForm from './SubscriptionPlanModuleFeaturesForm';
import subscriptionPlansService from '../../../../../../../services/api/subscriptions/subscriptionPlansService';
import { ListLoader } from '../../../../../../../components/loaders';


function CreateSubscriptionPlanPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const location = useLocation();

    const { planId } = useParams();
    const { addToast } = useToast();

    const [step, setStep] = useState<number>(1);

    const queryClient = useQueryClient();
    const queryKey = ['subscription', 'plan', planId];
    const planQuery = useQuery({
        queryKey: queryKey,
        queryFn: () => subscriptionPlansService.getPlan(Number(planId)),
        //initialData: { ...module },
        //placeholderData: { ...plan },
        enabled: !!planId,
        /*  staleTime: Infinity,
         retryDelay: Infinity,
         refetchOnWindowFocus: false, // Disable refetching on window focus
         refetchOnMount: false, // Disable refetching on component mount
         refetchInterval: false, // Disable interval-based refetching */
    });


    useEffect(() => {
        dispatch(changePageInfo({ title: planId ? "Edit Subscription Plan" : "Create Subscription Plan" }));
    }, []);


    const saveMutation = useMutation({
        mutationFn: (data: any) => {
            return planId ? subscriptionPlansService.updatePlan(Number(planId), data) : subscriptionPlansService.createPlan(data);
        }
    });


    const handleFormSubmit = async (formData: any, { resetForm }: any) => {
        const payload = {
            ...formData.details,
            moduleAndFeatures: formData.moduleAndFeatures.map((module: any) => ({
                moduleId: module.id,
                featureIds: module.features.map((feature: any) => feature.id),
            })),
        };

        saveMutation.mutate(payload, {
            onSuccess: (data) => {
                addToast({
                    text: `Subscription plan ${planId ? 'updated' : 'added'} successfully`,
                    type: 'success'
                });

                setTimeout(() => {
                    if (planId) {
                        queryClient.invalidateQueries({
                            queryKey,
                            refetchType: 'none',
                        });
                    }
                    queryClient.invalidateQueries({
                        queryKey: ['subscriptions', 'plans'],
                    });
                }, 50);

                navigate('/subscriptions/plans');
            },
            onError: () => {
            }
        });
    }



    return (
        <React.Fragment>
            {
                ((!planQuery.isLoading && !planQuery.isError && planQuery.data) || !planId) &&
                <div>
                    <Formik
                        initialValues={{
                            details: {
                                name: planQuery.data?.name || '',
                                description: planQuery.data?.description || '',
                                defaultPrice: planQuery.data?.defaultPrice || '',
                                defaultFrequency: planQuery.data?.defaultFrequency || '',
                                target: planQuery.data?.target || '',
                                status: planQuery.data?.status || 'ACTIVE',
                            },
                            moduleAndFeatures: planQuery.data?.modules || [],
                        }}
                        validationSchema={validationSchema}
                        onSubmit={handleFormSubmit}
                        validateOnMount
                        enableReinitialize
                    >
                        {({ values, errors, touched, setErrors, setFieldError, setFieldValue, setFieldTouched, submitCount, isValid, isInitialValid, }) => (
                            <Form className="">
                                <div className='flex flex-row-reverse flex-wrap justify-between mb-1'>
                                    <Button type='submit' className='mb-4' disabled={!isValid}>Save</Button>

                                    {
                                        saveMutation.error &&
                                        <div className='inline-block bg-red-500/5 rounded-lg px-4 py-2 mb-4'>
                                            <p className='text-red-500'>{saveMutation.error as unknown as string}</p>
                                        </div>
                                    }
                                </div>


                                <div className='grid lg:grid-cols-8 gap-10'>
                                    <div className='col-span-2'>
                                        <div className='bg-white dark:bg-gray-800 shadow-md dark:shadow-none p-8 rounded-xl flex lg:flex-col flex-wrap gap-5'>
                                            <p className={classNames({ 'font-semibold text-blue': step === 1 })}>1. Subscription Plan Details</p>
                                            <p className={classNames({ 'font-semibold text-blue': step === 2 })}>2. Features & Modules</p>
                                            {/* <p className={classNames({ 'font-semibold text-blue': step === 3 })}>3. Schools Enrolled</p> */}
                                        </div>
                                    </div>


                                    <div className='col-span-6 bg-white dark:bg-gray-800 shadow-md dark:shadow-none p-8 rounded-xl'>
                                        {step === 1 && <SubscriptionPlanDetailsForm onStepChange={(step) => setStep(step)} isSubmitting={saveMutation.isPending} />}
                                        {step === 2 && <SubscriptionPlanModuleFeaturesForm onStepChange={(step) => setStep(step)} isSubmitting={saveMutation.isPending} />}
                                        {/* {step === 3 && <SubscriptionPlanSchoolsForm onStepChange={(step) => setStep(step)} />} */}
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            }


            {
                // if it's loading
                planQuery.isLoading &&
                <ListLoader loadingText='Loading Plan' className='px-8 py-24' />
            }

            {/*   {
                // if is not found
                !planQuery.isLoading &&  && pageStatus.notFound &&
                <ErrorLoading title='Plan Not Found' message='Plan may have been removed or never existed' className='px-8 py-24' />
            } */}

            {
                // if there's an error loading
                !planQuery.isLoading && planQuery.isError &&
                <ErrorLoading title='Error Loading Plan' message='An unexpected error occurred while loading plan' className='px-8 py-24' onTryAgain={() => planQuery.refetch()} />
            }
        </React.Fragment>
    );
}



export const detailsValidationSchema = yup.object().shape({
    //name: yup.string().trim().required('Name is required'),
    ///^[a-zA-Z0-9]*$/
    name: yup.string().trim().matches(/^[^!@#$%^&*()]+$/, 'No special characters are allowed').required('Plan name is required'),
    description: yup.string().trim().required('Description is required'),
    defaultPrice: yup.string().matches(regexStrings.money, 'Invalid money format').required('Price is required'),
    defaultFrequency: yup.string().trim().required('defaultFrequency is required'),
    target: yup.string().trim().required('Type is required'),
    status: yup.string().trim().required('Status is required'),
});


/* const modulesFeaturesValidationSchema = yup.object().shape({
    moduleId: yup.number().integer().required().label(""),
    featureIds: yup.array().min(1).required(),
}); */

const validationSchema = yup.object().shape({
    details: detailsValidationSchema,
    // moduleAndFeatures: yup.array().of(modulesFeaturesValidationSchema),
    moduleAndFeatures: yup.array().min(1, 'At lease a module is require to create a subscription plan'),
});


export default CreateSubscriptionPlanPage;