import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useSearchParams } from 'react-router-dom';
import { format } from 'date-fns';

import thirdPartyService from '../../../../../../services/api/payments/thirdPartyService';
import { LinkButton, Pagination } from '../../../../../../components/common';
import { paginationInfo } from '../../../../../../utils/pagination';
import { changePageInfo } from '../../../../../../state/store/reducers/ui/headerSlice';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ListState } from '../../../../../../components/blocks';
import { Menu } from '@headlessui/react';
import PopoverWithFloat from '../../../../../../components/hoc/PopoverWithFloat';
import useToast from '../../../../../../state/hooks/useToast';
import ThirdPartyDetailsModal from '../modals/ThirdPartyDetailsModal';


function ThirdPartyListPage() {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    const { addToast } = useToast();

    const [thirdPartyDetails, setThirdPartyDetails] = useState({ open: false, thirdParty: null });


    // get query params
    const query: any = {
        pager: {
            page: Number(searchParams.get('page')) || 1,
        },
        filters: {},
    };
    if (searchParams.get('name')) { query.filters['name'] = searchParams.get('name') || '' }
    if (searchParams.get('primaryContact')) { query.filters['primaryContact'] = searchParams.get('primaryContact') || '' }


    useEffect(() => {
        dispatch(changePageInfo({ title: "Third Parties" }));
    }, [searchParams, dispatch]);


    const queryKey = ['payments', 'third-paries', query];
    const queryClient = useQueryClient();
    const thirdPartiesQuery = useQuery({
        queryKey: queryKey,
        queryFn: () => thirdPartyService.getThirdParties({ ...query.pager, ...query.filters }),
        staleTime: Infinity,
        retryDelay: Infinity,
        refetchOnWindowFocus: false, // Disable refetching on window focus
        refetchOnMount: false, // Disable refetching on component mount
        refetchInterval: false, // Disable interval-based refetching
    });



    const statusMutation = useMutation({
        mutationFn: ({ id, ...statusObj }: any) => thirdPartyService.updateThirdPartyStatus(id, statusObj),
        onSuccess: (data) => {
            addToast({
                text: `Third party ${data?.status} successfully`,
                type: 'success',
            });

            queryClient.setQueryData<any>(queryKey, (oldData: any) => {
                if (oldData) {
                    const index = oldData.data.findIndex((item: any) => item.id === data.id);
                    const newData = JSON.parse(JSON.stringify(oldData));
                    newData.data[index] = data;
                    return newData;
                }
            });
        },
        onError: (_, data) => {
            addToast({
                text: `Sorry, could not ${data?.status} third party`,
                type: 'error',
            });
        }
    });


    return (
        <div className=''>
            <div className='flex flex-row-reverse flex-wrap justify-between mb-1'>
                <LinkButton to="/payments/third-parties/new" onClick={() => null} className='mb-4'>Create Third Party</LinkButton>
            </div>

            {
                // if there're third parties
                !thirdPartiesQuery.isError && thirdPartiesQuery.isFetched && thirdPartiesQuery.isFetched && thirdPartiesQuery.data?.data && thirdPartiesQuery.data?.data.length > 0 &&
                <React.Fragment>
                    <div className='max-w-full overflow-x-auto mb-6'>
                        <table className="border-collapse table-auto w-full">
                            <thead>
                                <tr className='text-left border-b-2 dark:border-gray-700'>
                                    <th className='py-6 px-6'>Company</th>
                                    <th className='py-6 px-6'>Domain</th>
                                    <th className='py-6 px-6'>E-Mail</th>
                                    <th className='py-6 px-6'>Source</th>
                                    <th className='py-6 px-6'>Status</th>
                                    <th className='py-6 px-6'>Last Used</th>
                                    {/*  <th className='py-6 px-6'>Phone No.</th>
                                    <th className='py-6 px-6'>Source</th>
                                    <th className='py-6 px-6'>Date Created</th>*/}
                                    <th className='py-6 px-6 text-right'>Action</th>
                                </tr>
                            </thead>
                            <tbody className="divide-y dark:divide-gray-700/60">
                                {
                                    thirdPartiesQuery.data?.data.map((thirdPartyItem: any) =>
                                        <tr key={thirdPartyItem.id}>
                                            <td className='py-4 px-6'>
                                                <button type='button' onClick={() => setThirdPartyDetails({ open: true, thirdParty: thirdPartyItem })} className='transition-colors duration-300 hover:text-blue-500'>
                                                    {thirdPartyItem?.companyName}
                                                </button>
                                                {/* <Link to={`/payments/third-parties/${thirdPartyItem.id}`} state={{ thirdParty: thirdPartyItem }} className="transition-colors duration-300 hover:text-blue-500">
                                                    {thirdPartyItem?.companyName}
                                                </Link> */}
                                            </td>
                                            <td className='py-4 px-6'>{thirdPartyItem?.domainName}</td>
                                            <td className='py-4 px-6'>{thirdPartyItem?.email}</td>
                                            <td className='py-4 px-6'>{thirdPartyItem?.dataSource}</td>
                                            <td className='py-4 px-6'>
                                                {
                                                    thirdPartyItem?.status === "ACTIVE" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-green-500/10 text-green-500 rounded-lg py-1 px-3 mr-3`}>{thirdPartyItem?.status}</span>
                                                }
                                                {
                                                    thirdPartyItem?.status !== "ACTIVE" && thirdPartyItem?.status !== "SUSPENDED" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-yellow-500/10 text-yellow-500 rounded-lg py-1 px-3 mr-3`}>{thirdPartyItem?.status}</span>
                                                }
                                                {
                                                    thirdPartyItem?.status === "SUSPENDED" &&
                                                    <span className={`inline-block font-medium text-sm uppercase bg-red-500/10 text-red-500 rounded-lg py-1 px-3 mr-3`}>{thirdPartyItem?.status}</span>
                                                }
                                            </td>
                                            <td className='py-4 px-6 whitespace-nowrap'>
                                                {
                                                    thirdPartyItem?.lastUsed ? format(new Date(thirdPartyItem?.lastUsed), 'd LLL yyyy h:mm a') : 'N/A'
                                                }
                                            </td>
                                            <td className='py-4 px-6 text-right'>
                                                <Menu as="div" className="relative inline-block text-left">
                                                    <PopoverWithFloat placement='bottom-end' portal>
                                                        <Menu.Button type='button' className={`w-9 h-9 transition-colors duration-300 bg-opacity-0 rounded-full hover:bg-black/5 dark:hover:bg-white/5 ui-open:bg-black/5 dark:ui-open:bg-white/5`}>
                                                            <i className="ri-more-2-fill"></i>
                                                        </Menu.Button>

                                                        <Menu.Items as='div' unmount={false} className="w-48 right-0 origin-top-right rounded-xl bg-white dark:bg-gray-900 shadow-xl dark:shadow-gray-700/60 overflow-hidden px-2 py-3">
                                                            {/*  <Menu.Item as="button" onClick={() => setThirdPartyDetails({ open: true, thirdParty: thirdPartyItem })} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                View Details
                                                            </Menu.Item> */}
                                                            <Menu.Item as={Link} to={`/payments/third-parties/${thirdPartyItem.id}`} state={{ thirdParty: thirdPartyItem }} className="block w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                Go to Full Details
                                                            </Menu.Item>
                                                            {
                                                                (thirdPartyItem?.status === 'INACTIVE' || thirdPartyItem?.status === 'SUSPENDED') &&
                                                                <Menu.Item as="button" type='button' onClick={() => statusMutation.mutate({ id: thirdPartyItem?.id, status: 'ACTIVE' })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                    Activate
                                                                </Menu.Item>
                                                            }
                                                            {
                                                                thirdPartyItem?.status === 'ACTIVE' &&
                                                                <Menu.Item as="button" type='button' onClick={() => statusMutation.mutate({ id: thirdPartyItem?.id, status: 'SUSPENDED' })} className="w-full text-left hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg px-4 py-3">
                                                                    Suspend
                                                                </Menu.Item>
                                                            }
                                                        </Menu.Items>
                                                    </PopoverWithFloat>
                                                </Menu>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='flex justify-between flex-wrap'>
                        <p className='mb-3 mr-8 text-gray-500 dark:text-gray-400'>{paginationInfo(thirdPartiesQuery.data.pager.pageSize, thirdPartiesQuery.data.pager.totalCount, thirdPartiesQuery.data?.pager.page)}</p>

                        <Pagination
                            currentPage={thirdPartiesQuery.data?.pager.page}
                            totalCount={thirdPartiesQuery.data?.pager.totalCount}
                            pageSize={thirdPartiesQuery.data.pager.pageSize}
                            onPageChange={(page) => {
                                setSearchParams(current => ({
                                    ...Object.fromEntries(current.entries()),
                                    page: page.toString(),
                                }));
                            }}
                        />
                    </div>
                </React.Fragment>
            }

            <ListState
                hasSearch={Object.keys(query.filters).length > 0}
                listCount={thirdPartiesQuery.data?.data.length || 0}
                totalDataCount={thirdPartiesQuery.data?.pager.total || 0}
                isLoading={thirdPartiesQuery.isLoading}
                isError={thirdPartiesQuery.isError}
                onTryAgain={() => thirdPartiesQuery.refetch()}
            />



            <ThirdPartyDetailsModal
                open={thirdPartyDetails.open}
                thirdParty={thirdPartyDetails.thirdParty}
                onClose={() => setThirdPartyDetails(prevState => ({ ...prevState, open: false }))}
            />
        </div>
    );
}

export default ThirdPartyListPage;