import React, { RefObject, createRef, useEffect, useState } from 'react';
import { Input } from './Input';


function OTPInput({ value = '', length = 0, onChange }: Props) {
    const [code, setCode] = useState('');
    const inputRefs: RefObject<HTMLInputElement>[] = Array(length).fill(null).map(() => createRef());

    useEffect(() => {
        setCode(value);
    }, [value]);


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        //if (/^[0-9]$/.test(value)) {
        if (value.length === 1 && index < length - 1) {
            const nextRef = inputRefs[index + 1];
            if (nextRef) {
                nextRef.current?.focus();
                //const nextRef = inputRefs[index + 1];
            }
        }

        const otp = inputRefs.map(ref => ref.current ? ref.current.value : '').join('');
        onChange(otp);
        // }
    };


    const handleInputPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
        event.preventDefault();

        const pastedData = event.clipboardData.getData('text/plain').trim().slice(0, length);
        for (let i = 0; i < length; i++) {
            if (pastedData[i]) {
                const ref = inputRefs[i];

                if (ref.current) {
                    ref.current.value = pastedData[i];
                    ref.current.focus();
                }
            }
        }

        const otp = inputRefs.map(ref => ref.current ? ref.current.value : '').join('');
        onChange(otp);
    };


    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        const pressedKey = event.key;

        if (pressedKey === 'Backspace' || pressedKey === 'Delete') {
            const ref = inputRefs[index];
            if (!ref.current?.value && index > 0) {
                const prevRef = inputRefs[index - 1];
                prevRef.current && prevRef?.current.focus();
            }
        }
    };

    return (
        <div className='flex w-full gap-3.5'>
            {Array(length)
                .fill('')
                .map((_, itemIndex) => (
                    <Input
                        key={`otp-input-${itemIndex}`}
                        ref={inputRefs[itemIndex]}
                        autoFocus={itemIndex === 0}
                        type="text"
                        className='max-w-20 text-center text-white text-2xl py-4'
                        maxLength={1}
                        value={code[itemIndex] || ''}
                        onChange={(event) => handleInputChange(event, itemIndex)}
                        onKeyDown={(event) => handleInputKeyDown(event, itemIndex)}
                        onPaste={handleInputPaste}
                    />
                ))}
        </div>
    );
}


interface Props {
    value?: string;
    length: number;
    onChange: (otp: string) => any;
}

export default OTPInput;